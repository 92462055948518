@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

// row

.planRow {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    margin-left: 44px;
}

.invalid > .cell label {
    color: $red;
}

.plan { // this is row minus the X
    display: inline-flex;
    flex-direction: row;
    height: 48px;
    border-bottom: 1px solid $grey_2;
    border-left: 1px solid $grey_2;
    border-right: 1px solid $grey_2;
}

.topBorder {
    position: absolute;
    margin-top: -1px;
    height: 1px;
    width: 100%;
    background-color: $grey_2;
}

// cell

.cell {
    position: relative;
    background-color: white;
    border-left: 1px solid $grey_2;
}

.cell label {
    position: absolute;
    top: 0px;
    left: 0px;
    padding-top: 9px;
    padding-left: 12px;
    color: $grey_4;
    font-size: 10px;
    font-weight: medium;
    pointer-events: none;
}

// input

.inputContainer {
    @extend .cell;
    display: flex;
    flex-direction: column;
}

.inputContainer > div:last-child {
    flex: 1 1 auto;
}

.inputContainer input {
    flex: 1 1 auto;
    border: 0;
    color: $grey_6;
    font-size: 14px;
    padding-top: 14px;
}

// input toggle

.inputToggleContainer {
    @extend .cell;
    display: flex;
    flex-direction: row;
}

.inputToggleContainer > div:first-child {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.inputToggleContainer > div:first-child > div {
    margin: auto 0 auto 0;
    padding-left: 12px;
    padding-top: 14px;
    color: $grey_2;
}

.inputToggleContainer input {
    width: 100%;
    flex: 1 1 auto;
    border: 0;
    color: $grey_6;
    font-size: 14px;
    padding-top: 14px;
}

.inputToggleContainer > div:last-child > div {
    @include border-radius(5px);
    display: flex;
    margin-top: 9px;
    margin-right: 9px;
    background-color: $grey_1;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.inputToggleContainer > div:last-child > div > p {
    margin: auto;
    text-align: center;
    color: $grey_4;
    font-size: 10px;
    font-weight: medium;
}

// select

.selectContainer {
    @extend .cell;
    position: relative;
}

.select {
    height: 48px;
    display: block;
}

// individual containers

.movementContainer {
    @extend .selectContainer;
    border-left: 0;
    width: 252px;
}

.autoregulationContainer {
    @extend .selectContainer;
    width: 154px;
}

.sensorContainer {
    @extend .selectContainer;
    width: 130px;
}

.minSetsContainer {
    @extend .inputContainer;
    width: 65px;
}

.minSetsWithToggleContainer {
    @extend .inputToggleContainer;
    width: 125px;
}

// TODO: make this less hacky
.minSetsWithToggleContainer > div:last-child > div {
    width: 50px;
}

.maxSetsContainer {
    @extend .inputContainer;
    width: 73px;
}

.repsContainer {
    @extend .inputToggleContainer;
    width: 84px;
}

.loadContainer {
    @extend .inputToggleContainer;
    width: 90px;
}

.startLoadContainer {
    @extend .inputToggleContainer;
    width: 120px;
}

.quantifierContainer {
    @extend .selectContainer;
    width: 185px;
}

.targetContainer {
    @extend .inputContainer;
    width: 60px;
}

.rangeContainer {
    @extend .inputContainer;
    width: 65px;
}

.metricContainer {
    @extend .selectContainer;
    width: 182px;
}

// TODO: do this one
.speedWorkContainer {
    @extend .inputContainer;
    width: 90px;
}

.speedWorkContainer input {
    margin-top: 18px;
    margin-left: 12px;
}

.actionContainer {
    width: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('https://assets.reponestrength.com/grayx.png');
    cursor: pointer;
}