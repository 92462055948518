@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.title {
    padding-top: 24px;
    padding-bottom: 7px;
}

.grid {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 20px;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 5px;
}

.grid a {
    text-decoration: none;
}

.editButton {
    @extend .outlineButton;
    width: 100px;
}

.labelContainer {
    display: inline-grid;
    grid-template-columns: auto auto auto;
    margin-top: 15px;
    grid-column-gap: 25px;
}

.label {
    font-size: 10px;
    font-weight: 400;
    color: $header_text;
}
