@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.banner {
    @include no-drag;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: $red;
    color: white;
    text-align: center;
}

.tappableBanner {
    @extend .banner;
    cursor: pointer;
}
