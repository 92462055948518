@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.container {
    position: relative;
    margin-left: 44px;
}

.containerSmall {
    @extend .container;
    height: 32px;
    overflow: hidden;
}

.containerBig {
    @extend .container;
    height: 40px;
}

.container > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 252px;
    height: 100%;
}

// select

.select {
    background-color: $grey_6;
    border-right: 2px solid $grey_1;
    width: 253px;
}

// cover

.cover {
    background-color: $grey_2;
    line-height: 30px;
    color: $grey_4;
    font-size: 12px;
    font-weight: medium;
    cursor: pointer;
}

.cover:hover {
    background-color: $grey_3;
}

.cover > span {
    font-size: 16px;
    margin-left: 17px;
    margin-right: 6px;
}
