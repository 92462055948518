@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

// ROW

.athletesContainer {
    white-space : nowrap;
}

.checkbox {
    display: inline-block;
    width: 30px;
    margin: 0px;
}

.athletesTable {
    display: inline-block;
    white-space : nowrap;
    background-color: $cell_bg;
    border-top: 1px solid $border_color;
    border-left: 1px solid $border_color;
    border-bottom: 1px solid $border_color;
    margin-top: -1px; // hack
    padding: 0;
    text-decoration: none;
}

// COLUMNS

.rosterCell {
    display: inline-block;
    line-height: 40px;
    color: $cell_text;
    font-size: 14px;
    border-right: 1px solid $border_color;
    padding-left: 5px;
    width: 195px;
    height: 40px;
 }

.avatar {
    display: inline-block;
    border-right: 1px solid $border_color;
    vertical-align: top;
}

.avatar > img {
    display: block;
    width: 40px;
    height: 40px;
}

.name {
    @extend .rosterCell;
    @extend .hoverable;
    cursor: pointer;
    width: 170px;
    padding-right: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
}

.edit {
    display: inline-block;
    position: absolute;
    left: 235px;
    height: 20px;
    padding: 10px;
    cursor: pointer;
}

.edit:hover {
    filter: contrast(0%);
}

.category {
    @extend .rosterCell;
    width: 295px;
    line-height: 37px;
    height: 40px;
    margin: 0px;
}

.maxableExercise {
    @extend .rosterCell;
    width: 120px;
    margin: 0;
    padding: 0;
}

.maxableExercise > input {
    border: 0;
    margin: 0;
    padding-left: 5px;
    outline: none;
    width: 115px;
    height: 39px;
}
