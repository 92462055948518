@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.container {
    display: grid;
    grid-template-columns: 80px 120px 110px 100px 1fr;
    background-color: $cell_bg;
    border-left: 1px solid $border_color;
    border-right: 1px solid $border_color;
    border-bottom: 1px solid $border_color;
    font-size: 12px;
    color: $cell_text;
    margin-left: 24px;
    padding: 10px 15px 11px 15px;
    // align-items: center;
    line-height: 25px;
}

.container div:not(.repsContainer) > div:first-child {
    color: $cell_label;
    line-height: 20px;
    font-weight: 500;
    font-size: 10px;
}

// LOAD

.loadContainer {
    display: flex;
    flex-direction: row;
}

.weight {
    border: 1px solid $border_color;
    width: 50px;
    height: 25px;
    margin-right: 5px;
}

.weightUnit {
    @include border-radius(5px);
    display: flex;
    background-color: $grey_1;
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.weightUnit > p {
    margin: auto;
    text-align: center;
    color: $grey_4;
    font-size: 10px;
    font-weight: medium;
}

.hidden {
    visibility: hidden;
}

// RPE

.rpe {
    border: 1px solid $border_color;
    width: 60px;
    height: 25px;
}

// REPS

.manualReps {
    border: 1px solid $border_color;
    width: 60px;
    height: 25px;
}

.repsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
}

.rep {
    @include border-radius(5px);
    background-color: $off_target_rep;
    color: $rep_text;
    margin-right: 5px;
    padding: 9px;
    margin-top: 1px;
    margin-bottom: 1px;
}

.repOnTarget {
    @extend .rep;
    background-color: $on_target_rep;
}

.repFailed {
    @extend .rep;
    background-color: $failed_rep;
}

.repInvalid {
    @extend .rep;
    background-color: $invalid_rep;
}
