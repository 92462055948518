@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.container > div:first-child {
    margin-top: 0px;
}

.year {
    font-size: 14px;
    font-weight: bold;
    color: $filter_title;
    margin-top: 20px;
    line-height: 20px;
}

.month {
    font-size: 14px;
    color: $filter_text;
    line-height: 20px;
    cursor: pointer;
}

.month:hover {
    color: $filter_selected;
}

.selectedMonth {
    font-size: 14px;
    color: $filter_highlighted;
    line-height: 20px;
    cursor: pointer;
}
