@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.main {
    display: flex;
    flex-direction: row;
}

.main > div:first-child {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 150px;
    height: 100vh;
    overflow-y: auto;
}

.container {
    width: 100%;
}

.container > div:first-child {
    margin-top: 0px;
}

.dateLabel {
    margin-top: 20px;
    font-size: 16px;
    color: $content_title;
}

.cycleLabel {
    font-size: 10px;
    font-weight: 500;
    color: $exterior_label;
    margin-top: 7px;
    margin-bottom: 5px;
}

.paddedCycleLabel {
    @extend .cycleLabel;
    margin-top: 12px;
}
