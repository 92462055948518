@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.modal {
    width: 388px;
    // height: 401px;
    padding: 34px 29px 25px 37px;
}

.list {
    list-style-type: none;
    padding-left: 0px;
}

.list > li {
    margin-bottom: 10px;
}

.checked {
    padding-left: 5px;
}

.unchecked {
    padding-left: 5px;
    opacity: 0.5;
}

.manageMaxes {
    display: inline-block;
    text-decoration: none;
    color: $blue;
    margin-top: 10px;
    margin-bottom: 90px;
}

.done {
    @extend .fullButton;
    position: absolute;
    bottom: 25px;
    right: 29px;
    width: 100px;
}

.disabled {
    opacity: 0.5;
    cursor: auto;
}
