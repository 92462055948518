@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.notesContainer {
    flex: 1;
    background-color: white;
    margin-top: -20px;
    padding-left: 20px;
}

.notes {
    border: 0;
    width: 100%;
    min-width: 100px;
    max-width: 875px;
    font-size: 12px;
    font-weight: 500;
    color: $grey_4;
}

.notes::placeholder {
    color: $grey_3;
}

.notes:hover {
    outline-style: none;
    box-shadow: 0px 0px 3px var(--secondary-color),0px 0px 3px var(--secondary-color),0px 0px 3px var(--secondary-color);
}

.notes:focus {
    outline-style: none;
    box-shadow: 0px 0px 3px var(--secondary-color),0px 0px 3px var(--secondary-color),0px 0px 3px var(--secondary-color);
}
