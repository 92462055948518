@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.liveContainer {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $content_bg;
    overflow-y: scroll;
}

// NAV

.liveNav {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-left: 24px;
    padding-right: 14px;
}

.liveNav > svg {
    width: 28px;
    height: 28px;
    margin-top: auto;
    margin-bottom: auto;
}

.liveNav > h1 {
    margin-left: 15px;
    margin-top: auto;
    margin-bottom: auto;
}

.select {
    margin-left: 20px;
    width: 250px;
    margin-top: auto;
    margin-bottom: auto;
}

// FEED

.feedContainer {
    padding-left: 13px;
    padding-right: 13px;
    margin-top: 30px;
}

.feed {
    display: grid;
    height: 60px;
    padding: 0px;
    cursor: auto;
    margin-top: -1px;
    border: 1px solid $border_color;
    background-color: white;
}

.feed:hover {
    background-color: $cell_bg;
}

.feed > img:first-child {
    width: 60px;
    height: 60px;
    border-right: 1px solid $border_color;
}

// center it all vertically
.feed > div {
    margin-top: auto;
    margin-bottom: auto;
}

.feedback svg {
    margin-bottom: 2px;
}

.feedback > div:nth-child(2) {
     margin-left: 13px;
}

// second children of children are always descriptions
.feed > div > div:nth-child(2) {
    color: $cell_secondary_text;
    margin-top: 3px;
    font-size: 13px;
}

// timestamp
.time {
    text-align: right;
    padding-right: 10px;
}

.completed {
    @extend .feed;
    grid-template-columns: 70px 1fr 250px 100px;
}

.autoregulated {
    @extend .feed;
    grid-template-columns: 70px 250px 250px 250px 250px 1fr;
}

.manual {
    @extend .feed;
    grid-template-columns: 70px 250px 250px 250px 1fr;
}
