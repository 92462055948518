@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.modal {
    width: 700px;
    padding: 32px 24px 24px 24px;
}

.avatar {
    display: inline-block;
}

.horizontalGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 6px;
}

.horizontalGrid input {
    border-radius: 5px;
    border: 1px solid $border_color;
    display: flex;
    flex: 1;
    height: 40px;
    padding-left: 5px;
}

.optionsContainer {
    margin-top: 30px;
    float: right;
    display: flex;
    flex-direction: row;
}

.saveButton {
    @extend .fullButton;
    margin-left: 10px;
}

.deleteButton {
    @extend .textButton;
    color: var(--destructive-color);
}

.cardTitle {
    color: $cell_title;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 24px;
}

.label {
    color: $cell_field_label;
    margin-bottom: 6px;
    margin-top: 20px;
    font-size: 16px;
}
