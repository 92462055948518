@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

// container

.blockContainer {
    position: relative;
    margin-bottom: 12px;
}

// error

.invalid > .cell label {
    color: $red;
}

// hover border

.blockContainer > .hoverBorder {
    top: 43px;
}

.blockContainer > .hoverBorder:first-child {
    top: -5px;
}

.blockContainer > .hoverBorder:last-child {
    bottom: 5px;
}

// header

.blockHeader {
    // display: grid;
    // grid-template-columns: 256px 76px 78px;
    display: flex;
    flex-direction: row;
    margin-left: 44px;
    margin-bottom: 2px;
    height: 44px;
}

// footer

.addMovement {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 310px 70px 80px;
}

.addMovement > div:not(:first-child) {
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    color: $grey_3;
    font-size: 12px;
    font-weight: medium;
}

.addMovement > div:not(:first-child):hover {
    color: $grey_4;
}

// cell

.cell {
    position: relative;
    background-color: $grey_6;
    border-right: 2px solid $grey_1;
}

.cell label {
    position: absolute;
    top: 0px;
    left: 0px;
    padding-top: 9px;
    padding-left: 12px;
    color: white;
    font-size: 9px;
    font-weight: medium;
    pointer-events: none;
}

// select

.select {
    background-color: $grey_6;
    border-right: 2px solid $grey_1;
    width: 253px;
}

// input

.inputContainer {
    @extend .cell;
    display: flex;
    flex-direction: column;
}

.inputContainer > div:last-child {
    flex: 1 1 auto;
}

.inputContainer input {
    flex: 1 1 auto;
    border: 0;
    background-color: $grey_6;
    font-size: 14px;
    padding-top: 14px;
    color: white;
    width: 100%;
}

.minSets {
    @extend .inputContainer;
    width: 76px;
}

.maxSets {
    @extend .inputContainer;
    width: 78px;
}
