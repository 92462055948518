@import "src/shared_styles/plan";

.main > .settingsSection:first-child {
    margin-top: 0;
}

.settingsSection {
    margin-top: 30px;
    margin-bottom: 42px;
}

.settingsSection > h1 {
    margin-bottom: 19px;
}

.settingsSection > label {
    font-size: 14px;
    font-weight: bold;
    color: $grey;
}

.settingsSection > p {
    font-size: 14px;
    color: $grey_4;
}

.saveButton {
    @extend .textButton;
}

// overrides for plan

.movementContainer {
    pointer-events: none;
    opacity: 0.5;
}

.planRow {
    margin-top: 10px;
    margin-left: 0px;
}
