@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.stationrow {
    width: 300px;
    display: grid;
    grid-template-columns: 150px 150px;

    background-color: $cell_bg;
    border-top: 1px solid $border_color;
    border-right: 1px solid $border_color;
    border-left: 1px solid $border_color;
    border-bottom: 1px solid $border_color;
    margin-top: -1px; //hack
}

// hacking the display, this is ugly
.stationrow > div:first-child > input {
    // padding-left: 17px;
    // padding-top: 12px;
    // padding-bottom: 13px;
    border-right: 1px solid $border_color;
    color: $cell_text;
}

// hack for station number
.stationrow div {
    width: 150px;
    height: 45px;
}

.stationrow div > input {
    border: 0px;
    width: 100%;
    height: 100%;
    color: $cell_text;
}

.update {
    padding-top: 12px;
    padding-left: 12px;
    font-size: 12px;
    font-weight: medium;
}

.upToDate {
    @extend .update;
    color: $grey_4;
}

.updateRequired {
    @extend .update;
    color: $red;
}

.updateAvailable {
    @extend .update;
    color: #D0A00E;
}