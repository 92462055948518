@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.container {
    position: relative;
}

.transparent {
    opacity: 0.2;
}

.hidden {
    opacity: 0;
}

// header

.headerContainer:hover .builderMenu {
    display: flex;
    flex-direction: column;
}

// hover menu

.builderMenu {
    position: absolute;
    left: 13px;
    vertical-align: middle;
    height: 100%;
    display: none;
    z-index: 1;
}

.builderMenuOpen {
    @extend .builderMenu;
    display: flex;
    flex-direction: column;
}

// TODO: try to solve this with flexbox
// hacky way to CSS alignment for now, assumes 3 children exactly which isn't great

.builderMenu > div:first-child {
    position: absolute;
    top: -11px;
}

.builderMenu > div:nth-child(2) {
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
}

.builderMenu > div:last-child {
    position: absolute;
    bottom: -11px;
}

.builderMenuItem {
    margin-right: 2px;
    margin-bottom: 4px;
    cursor: pointer;
    @include no-drag();
}

.builderMenuItem svg {
    width: 20px;
    height: 20px;
    stroke: $grey_3;
    fill: $grey_1;
}

.builderMenuItem:hover svg {
    transform: scale(1.2);
    stroke: $grey_4;
}

.selectedMenuItem {
    @extend .builderMenuItem;
}

.selectedMenuItem svg {
    transform: scale(1.2);
    stroke: $grey_4;
}

.dragMenuItem {
    @extend .builderMenuItem;
    cursor: grab;
}

.dragMenuItem:active svg {
    stroke: $blue;
}
