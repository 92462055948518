@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.microcycle {
    position: relative;
    margin-top: 22px;
}

.microcycle h2 {
    margin-left: 44px;
}

.microcycle h2 span:hover {
    cursor: pointer;
}

.microcycle h2 img {
    display: none;
}

.microcycle h2:hover img {
     display: inline-block;
}

// border

.microcycleBorder {
    background-color: $grey_2;
    width: 1px;
    height: 100%;
    top: 0px;
    left: 23px;
    position: absolute;
}

.microcycle:hover > .microcycleBorder {
    background-color: $grey_3;
}

// add session

.addSession {
    display: inline-block;
    margin-top: 7px;
    margin-left: 44px;
    color: $grey_4;
    font-size: 12px;
    font-weight: medium;
    cursor: pointer;
}

.addSession:hover {
    color: $grey;
}

.addSession > span {
    font-size: 16px;
    margin-right: 8px;
}
