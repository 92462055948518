@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.labelsContainer {
    display: inline-block;
    white-space : nowrap;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 5px;
}

.rosterLabel {
    @extend .tableHeaderLabel;
    display: inline-block;
}

.selectLabel {
    @extend .rosterLabel;
    width: 243px;
    margin-left: 5px;
}

.avatarLabel {
    @extend .rosterLabel;
    width: 41px; // 40px avatar + 1 for right border
}

.nameLabel {
    @extend .rosterLabel;
    width: 201px;
}

.categoryLabel {
    @extend .rosterLabel;
    width: 301px;
}

.maxableExerciseLabel {
    @extend .rosterLabel;
    width: 111px;
    margin-right: 10px;
}
