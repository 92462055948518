@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.navBackground {
    background-color: var(--nav-background-color);
    width: 100%;
}

.nav {
    height: 57px;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
}

.nav a {
    display: inline-block;
    text-decoration: none;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
}

.logo {
    vertical-align: middle;
    margin-right: 10px;
    width: 28px;
}

.inactive div {
    color: var(--nav-text-color);
    padding-top: 20px;
    padding-bottom: 20px;
}

.inactive div:hover {
    color: var(--nav-active-color);
    border-bottom: 3px solid var(--nav-active-color);
}

.inactive div:hover > svg {
    height: 10px;
    width: 10px; 
    stroke: var(--nav-active-color);
    fill: var(--nav-active-color);
}

.inactive svg {
    height: 10px;
    width: 10px; 
    stroke: var(--nav-text-color);
    fill: var(--nav-text-color);
}

.active svg {
    height: 10px;
    width: 10px; 
    stroke: var(--nav-active-color);
    fill: var(--nav-active-color);
}

.active div {
    color: var(--nav-active-color);
    border-bottom: 3px solid var(--nav-active-color);
    padding-top: 20px;
    padding-bottom: 20px;
}

.account {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--nav-text-color);
}

.account:hover {
    cursor: pointer;
    color: var(--nav-active-color);
}

.email {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 400;
    padding-top: 20px;
}

.email > div {
    font-size: 6px;
    line-height: 12px;
    margin-top: 2px;
    margin-left: 5px;
}

.menu {
    @include border-radius(3px);
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    height: 0px;
    visibility: hidden;
    background-color: white;
    border: 1px solid $border_color;
}

.menu > div {
    @extend .hoverable;
    padding: 15px;
    color: var(--primary-color);
    font-size: 12px;
}

.menu > a {
    @extend .hoverable;
    padding: 15px;
    color: var(--primary-color);
    font-size: 12px;
}

.account:hover .menu {
    height: auto;
    visibility: visible;
}