@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.filter {
    display: block;
    margin-left: 2px;

    color: $filter_text;
    text-decoration: none;
    line-height: 24px;
    font-size: 16px;
    font-weight: normal;
}

.filter:hover {
    color: $filter_selected;
}

.selectedFilter {
    @extend .filter;
    color: $filter_selected;
    font-weight: bold;
}

.teamFilter {
    @extend .filter;
}

.selectedTeamFilter {
    @extend .selectedFilter;
}

.groupFilter {
    @extend .filter;
    padding-left: 20px;
}

.selectedGroupFilter {
    @extend .selectedFilter;
    padding-left: 20px;
}
