@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.exportButton {
    @extend .fullButton;
    width: 100px;
    margin-left: 20px;
    margin-top: 15px;
    padding: 11px;
}
.exportButton:disabled,
.exportButton[disabled] {
    opacity: 0.5;
    cursor: wait;
}

.container {
    background-color: white;
    flex-direction: column;
    padding: 30px;
    margin-bottom: 50px;
    border: 1px solid $border_color;
}

.filtersContainer {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    font-size: 15px;
    margin-top: 20px;
}

.comingSoon {
    margin-top: 40px;
    margin-bottom: 20px;
    color: $grey_4;
}

.blank {
    height: 400px;
    background-color: white;
    border: 1px solid $border_color;
}

// data exports

.pageSpinner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.rowSpinner {
    position: relative;
    bottom: 15px;
    right: -10px;
    width: 50px;
    height: 50px;
}

.dataExport {
    @extend .row;
}

.dataExportStatus {
    float: right;
}

.disabled {
    pointer-events: none;
}

// OLD

.title {
    padding-bottom: 22px;
}

.title > div:first-child {
    margin-bottom: 20px;
}

.main > div:first-child {
    margin-top: 0px;
}

.graphTitle {
    margin-top: 24px;
    margin-bottom: 10px;
    font-size: 18px;
    color: $content_title;
}