// color pallette
$grey_6: #333333;
$grey: #4F4F4F;
$grey_4: #828282;
$grey_3: #BDBDBD;
$grey_2: #E0E0E0;
$grey_1: #F2F2F2;
$red: #EB5757;
$orange: #F2994A;
$yellow_3: #B58A09;
$yellow: #F2C94C;
$yellow_1: #FFF0C2;
$green: #219653;
$green_2: #27AE60;
$green_1: #6FCF97;
$blue: #2F80ED;
$blue_2: #2D9CDB;
$blue_1: #56CCF2;
$purple: #9B51E0;
$purple_1: #BB6BD9;
$clear: rgba(0, 0, 0, 0);

:root {
    // generalized
    --primary-color: #{$blue};
    --secondary-color: #{$yellow};
    --destructive-color: #{$red};

    // nav
    --nav-background-color: #{$grey_6};
    --nav-active-color: #{$yellow};
    --nav-text-color: #{$grey_3};
}

// login
$login_text: $grey_6;
$login_placeholder_text: $grey_3;

// header
$header_bg: white;
$header_title: $grey_6;
$header_text: $grey_4;
$header_subtitle: $grey_4;
$header_option: $grey_4;

// tabs
$tab_text: $grey_4;
$tab_selected: $grey_6;

// content
$content_bg: $grey_1;
$content_title: $grey;
$border_color: $grey_2;
$hover_bg: $content_bg;
$exterior_label: $grey_4;

// cell
$cell_top_border_color: $grey;
$cell_bg: white;
$cell_title: $grey;
$cell_section_text: $grey;
$cell_text: $grey;
$cell_secondary_text: $grey_4;
$cell_label: $grey_4;
$cell_field_label: $grey;

// textfield
$textfield_inactive: $grey_4;
$textfield_text: $grey;

// filters
$filter_title: $grey;
$filter_text: $grey_4;
$filter_selected: $grey_6;
$filter_highlighted: var(--primary-color);

// modal
$overlaytint: rgba(0, 0, 0, 0.4);
$modal_bg: white;
$modal_title: $grey_6;
$modal_subtitle: $grey_6;
$modal_text: $grey_6;

// autoregulation
$invalid_rep: $grey_3;
$on_target_rep: var(--primary-color);
$off_target_rep: var(--secondary-color);
$failed_rep: var(--destructive-color);
$rep_text: white; // TODO: if bg colors require diff colors, need to do one per type

// avatar
$image_overlay_text: white;

// graph
$graph_bg: white;
$graph_font_color: $grey_4;

:export {
    blue: $blue;
    red: $red;
    yellow: $yellow;
    grey_6: $grey_6;
    grey_3: $grey_3;
    graph_font_color: $graph_font_color;
    border_color: $border_color;
}
