@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.container {
    display: inline-block;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 15px;
    height: 55px;

    white-space : nowrap;
    background-color: $blue;
}

.container > span {
    display: inline-block;
}

// selected

.selected {
    color: white;
    width: 100px;
    opacity: 0.5;
    margin-left: 32px;
    margin-right: 20px;
}

// team

.team {
    width: 200px;
    margin-right: 30px;
}

// group

.group {
    width: 200px;
    margin-right: 30px;
}

// delete

.delete {
    @extend .textButton;
    color: white;
}

.delete svg {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 7px;
    padding-bottom: 1px;

    stroke: $grey_3;
    fill: white;
    width: 10px;
    height: 10px;
}

.disabled {
    opacity: 0.5;
}

// done

.done {
    @extend .outlineButton;
    color: white;
    border: 1px solid white;

    width: 100px;

    margin-right: 18px;
    float: right;
}

.done:hover {
    color: $blue;
    background-color: white;
}

.spinner {
    position: absolute;
    right: 40px;
    bottom: 35px;
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}
