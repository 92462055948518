@import "src/shared_styles/colors";

* {
    font-family: 'Roboto', sans-serif;
}

body {
    background-color: $content_bg;
}

h1 {
    font-size: 24px;
    font-weight: 400;
    color: $grey_6;
    margin: 0;
}

h2 {
    font-size: 20px;
    font-weight: bold;
    color: $grey_6;
    margin: 0;
}

.main {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 30px;
    margin-bottom: 100px;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
}

@mixin no-drag() {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

input {
    padding-left: 12px;
    box-sizing: border-box;
}

// row
// TODO: will be switched out with cells over time

.row {
    @include border-radius(7px);
    @extend .hoverable;

    display: block;
    text-decoration: none;
    background-color: $cell_bg;
    padding: 20px;
    margin-bottom: 5px;
    color: $cell_text;
    font-weight: 500;
    border: 1px solid $border_color;
    cursor: pointer;
}

// .row:hover {
//     border: 3px solid $yellow;
// }

// hoverable

.hoverable:hover {
    background-color: $hover_bg;
}

// title

.titlebg {
    background-color: $header_bg;
    width: 100%;
}

.title {
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 800;
    font-size: 32px;
    color: $header_title;
    padding-top: 30px;
    padding-bottom: 30px;
}

// tabs

.tab {
    display: inline;
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    margin-right: 30px;
    padding-bottom: 10px;
    color: $tab_text;
    cursor: pointer;
}

.tab:hover {
    border-bottom: 2px solid $tab_selected;
    color: $tab_selected;
}

.selectedTab {
    @extend .tab;
    font-weight: 400;
    border-bottom: 2px solid $tab_selected;
    color: $tab_selected;
    cursor: pointer;
}

// buttons

.outlineButton {
    @include border-radius(5px);
    @include no-drag();

    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    background-color: $clear;
    cursor: pointer;
    text-align: center;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.outlineButton:hover {
    background-color: var(--primary-color);
    color: white;
}

.fullButton {
    @include border-radius(5px);
    @include no-drag();

    padding: 13px;
    font-size: 16px;
    font-weight: 400;
    background-color: var(--primary-color);
    cursor: pointer;
    text-align: center;
    color: white;
}

.textButton {
    @include no-drag();
    
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    text-align: center;
    color: var(--primary-color);
}

// header labels

.tableHeaderLabel {
    color: $exterior_label;
    font-size: 10px;
    font-weight: 500;
    font-variant: small-caps;
    text-overflow: ellipsis;
    overflow: hidden;
}

// popup

.modal {
    @include border-radius(7px);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $modal_bg;
    outline-style: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $overlaytint;
}

// preview

.placeholderContainer {
    display: inline-block;
    margin-top: 10px;
    margin-left: 21px;
}

.preview {
    flex: 1;

    @include border-radius(25px);
    text-align: center;

    color: $yellow_3;
    border: 1px solid $yellow_3;
    background-color: $yellow_1;

    padding: 10px;
    margin-bottom: 20px;
 }

.svg {
    flex: 1;
    // width: 100%;
    max-width: 1024px;
    opacity: 0.5;
    @include no-drag();
}

// right click context menus

:global {
    .react-contextmenu {
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,.15);
        border-radius: .25rem;
        color: #373a3c;
        font-size: 16px;
        margin: 2px 0 0;
        min-width: 160px;
        outline: none;
        opacity: 0;
        padding: 5px 0;
        pointer-events: none;
        text-align: left;
        transition: opacity 250ms ease !important;
    }

    .react-contextmenu.react-contextmenu--visible {
        opacity: 1;
        pointer-events: auto;
        z-index: 9999;
    }

    .react-contextmenu-item {
        background: 0 0;
        border: 0;
        color: #373a3c;
        cursor: pointer;
        font-weight: 400;
        line-height: 1.5;
        padding: 3px 20px;
        text-align: inherit;
        white-space: nowrap;
    }

    .react-contextmenu-item.react-contextmenu-item--active,
    .react-contextmenu-item.react-contextmenu-item--selected {
        color: #fff;
        background-color: #20a0ff;
        border-color: #20a0ff;
        text-decoration: none;
    }

    .react-contextmenu-item.react-contextmenu-item--disabled,
    .react-contextmenu-item.react-contextmenu-item--disabled:hover {
        background-color: transparent;
        border-color: rgba(0,0,0,.15);
        color: #878a8c;
    }

    .react-contextmenu-item--divider {
        border-bottom: 1px solid rgba(0,0,0,.15);
        cursor: inherit;
        margin-bottom: 3px;
        padding: 2px 0;
    }
    .react-contextmenu-item--divider:hover {
        background-color: transparent;
        border-color: rgba(0,0,0,.15);
    }

    .react-contextmenu-item.react-contextmenu-submenu {
        padding: 0;
    }

    .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
    }

    .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
        content: "▶";
        display: inline-block;
        position: absolute;
        right: 7px;
    }
}
