.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin:-15px 0 0 -15px;
    -webkit-animation:spin 0.9s ease-in-out infinite;
    -moz-animation:spin 0.9s ease-in-out infinite;
    animation:spin 0.9s ease-in-out infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
