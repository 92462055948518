@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.modal {
    width: 567px;
    padding: 32px 22px 22px 22px;
    flex-direction: column;
}

.modal .optionsContainer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.doneButton {
    @extend .fullButton;
}

.chooseButton {
    @extend .fullButton;
}

.modal > h1 {
    margin-bottom: 30px;
}

.modal {
    font-size: 16px;
}

.modal li {
    line-height: 20px;
}