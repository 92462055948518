@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

// modal

.modal {
    width: 373px;
    padding: 32px 22px 22px 22px;
    flex-direction: column;
}

.modal > div {
    margin-top: 15px;
}

// subtitle

.modal .subtitle {
    margin-top: 10px;
    font-size: 16px;
    line-height: 20px;
    color: $modal_subtitle;
}

// dates

.dates {
    margin-top: 20px;
    flex-direction: row;
}

.dates > span {
    margin-left: 10px;
    margin-right: 10px;
}

.datePicker {
    display: flex;
    flex: 1;

    padding-left: 13px;
    height: 41px;
    width: 105px;

    font-size: 14px;
    outline: none;
    @include border-radius(5px);
    border: 1px solid $border_color;
}

// select

.select {
    @include border-radius(5px);
    border: 1px solid $border_color;
}

// errors

.modal img {
    margin-right: 5px;
}

.errorAssignee {
    font-size: 16px;
    font-weight: 700;
    color: $modal_text;
    line-height: 20px;
}

.errorDescription {
    font-size: 16px;
    color: $modal_text;
}

// options

.modal .optionsContainer {
    margin-top: 20px;
}

.doneButton {
    @extend .fullButton;
    width: 105px;
    float: right;
}

.deleteButton {
    @extend .textButton;
    color: $red;
    font-size: 10px;
    font-weight: 500;
    width: 80px;
    padding: 16px;
    float: right;
}
