@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.container {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    font-size: 15px;
}

.container > div {
    display: flex;
    flex-direction: column;
}

.container > div > div:first-child {
    margin-bottom: 3px;
    padding-left: 1px;
    font-size: 10px;
    font-weight: 500;
    color: $header_text;
}

.group {
    width: 210px;
    margin-right: 6px
}

.interval {
    width: 100px;
    margin-right: 6px
}

.timeRange {
    width: 130px;
}

.datePicker {
    display: flex;
    flex: 1;
    height: 41px;
    padding-left: 5px;
    width: 78px;

    outline: none;
    border-left: 0;
    border-top: 1px solid $border_color;
    border-bottom: 1px solid $border_color;
    border-right: 1px solid $border_color;
}

.startDatePicker {
    @extend .datePicker;
}

.endDatePicker {
    @extend .datePicker;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
