@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

// title area

.cardTitle {
    font-size: 18px;
    color: $modal_title;
    margin-bottom: 6px;
}

.description {
    color: $modal_subtitle;
    font-size: 14px;
    margin-bottom: 24px;
}

.description div {
    display: inline;
    font-weight: bold;
}

// container area

.container {
    margin-bottom: 24px;
}

// row header
.rowHeader {
    display: grid;
    grid-template-columns: 500px 500px 200px 42px;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 1px;
}

.rowHeader > span {
    @extend .tableHeaderLabel;
    display: inline-block;
}

// the add lift button
.addLift {
    width: 501px;
    line-height: 42px;
    background-color: white;
}
.addLift > div:first-child {
    margin-top: -1px; // hack
    border: 1px solid $border_color;
}

.doneButton {
    @extend .fullButton;
    width: 100px;
    float: right;
}
