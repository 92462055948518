@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.container {
    display: grid;
    grid-template-columns: 30px 200px 80px 110px 1fr;
    color: $cell_text;
    font-size: 12px;
    background-color: $cell_bg;
    border-left: 1px solid $border_color;
    border-right: 1px solid $border_color;
    border-bottom: 1px solid $border_color;
    margin-left: 12px;
    padding: 10px 15px 11px 15px;
}

.container div:first-child {
    margin-right: 20px;
    color: $cell_label;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
}

.container div {
    align-self: center;
}

.hoverableContainer {
    @extend .container;
    @extend .hoverable;
    cursor: pointer;
}
