@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

$event-bg: $blue;
$event-border-radius: 0;
$border-color: $grey_2;
$cell-border: $grey_2;
$calendar-border: $grey_2;
$out-of-range-bg-color: $grey_1;
// $today-highlight-bg: $blue_1;

.cal {
    max-width: 100vh;
    max-height: 60vh;
    height: 60vw;
    margin-left: 21px;
    margin-right: 21px;

    :global {
        @import "~react-big-calendar/lib/sass/styles";
        .rbc-day-bg {
            background-color: white;
        }
        .rbc-off-range-bg {
            background-color: $out-of-range-bg-color;
        }
        .rbc-today {
            background-color: $today-highlight-bg;
        }
        .rbc-date-cell a {
            color: $grey_6;
        }
    }
}

.description {
    margin-left: 21px;
    margin-top: 25px;
    margin-bottom: 21px;
}

.select {
    width: 250px;
    z-index: 9999;
    margin-right: -21px;
    float: right;
}

.calendarControls {
    max-width: 100vh;
}
