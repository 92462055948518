@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.container {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
}

.borderedContainer {
    @extend .container;
    border: 1px solid $border_color;
}

.avatarContainer {
    overflow: hidden;
    width: 72px;
    height: 72px;
    position: relative;
}

.avatarContainerSmall {
    @extend .avatarContainer;
    width: 40px;
    height: 40px;
}

.avatarContainerRounded {
    @extend .avatarContainer;
    @include border-radius(10px);
}

.avatarContainer:hover .avatarOverlay {
    opacity: 1;
}

.avatarImg {
    width: 100%;
    height: 100%;
}

.avatarOverlay {
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    vertical-align: center;
    background-color: $overlaytint;
}

.avatarOverlay div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

    text-align: center;
    color: $image_overlay_text;
    font-weight: 400;
    font-size: 12px;
}

.avatarOverlay img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    filter: contrast(9000%);
}

.description {
    margin: 15px;
}

input[type=file] {
    display: none;
}

.crop {
    display: block;
}

.upload {
    @extend .fullButton;

    margin: 10px;
    width: 100px;
    float: right;
    text-align: center;
}
