@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

// TODO: fix up color variable references

.main {
    margin-bottom: 0px;

    // killing margin left/right for mouseover purposes unique to this screen
    padding-top: 0px;
    padding-bottom: 300px; // because overflow:auto screws up dropdown menus
    margin-left: 0px;
    margin-right: 0px;

    width: 100%;
    overflow: auto;
}

.reorder {
    position: fixed;
    display: none;
    left: 13px;
    pointer-events: none;
}

.reorder svg {
    width: 20px;
    height: 20px;
    pointer-events: none;
    stroke: $blue;
    fill: $grey_1;
    transform: scale(1.2);
}

.hoverIndicator {
    display: none;
    opacity: 1;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 3px;
    background-color: $blue;
}

.spinner {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
}

// add microcycle

.addMicrocycle {
    display: inline-block;
    position: relative;
    margin-top: 17px;
    margin-left: 44px;
    color: $grey_3;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.addMicrocycle:hover {
    color: $grey;
}

.addMicrocycle > span {
    font-size: 16px;
    margin-right: 8px;
}

.microcycleBorder {
    background-color: $grey_2;
    width: 1px;
    height: 100%;
    top: 0px;
    left: -21px;
    position: absolute;
}

.addMicrocycle:hover .microcycleBorder {
    background-color: $grey_3;
}