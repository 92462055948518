@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

// HEADER AREA

.titleText {
    font-size: 24px;
    color: $content_title;
}

.optionsContainer {
    display: flex;
    flex-direction: row;
    height: 40px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px;
}

.columnsButton {
    @extend .textButton;
    padding: 17px;
    padding-left: 1px;
    padding-right: 0px;
    margin-right: 22px;
}

.columnsButton > img {
    margin-right: 6px;
}

.importButton {
    @extend .textButton;
    padding: 17px;
    padding-left: 0px;
    padding-right: 0px;
}

.importButton > img {
    margin-right: 5px;
}

.createButton {
    @extend .outlineButton;
    display: block;
    margin-left: auto;
}

.search {
    display: block;
    width: 100%;
    height: 40px;
    border: 1px solid $border_color;
}

// RESULTS

.resultsContainer {
    position: absolute;
    right: 0;
    left: 212px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 100px;
    overflow: auto;
}

.spinner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

// empty

.emptyContainer {
    margin: auto;
    margin-top: 75px;
    text-align: center;
}

.dashedContainer {
    border: 1px dashed $grey_3;
    text-align: center;
    display: inline-block;
    padding: 40px 80px 75px 80px;
}

.subheader {
    color: $grey_6;
    margin-bottom: 20px;
}

.description {
    color: $grey_6;
    font-size: 12px;
    margin-bottom: 30px;
}

.video {
    margin-bottom: 25px;
    min-width: 380px;
    min-height: 220px;
}

.or {
    margin: 10px;
    color: $cell_label;
}

.emptyButton {
    @extend .fullButton;
    margin-left: auto;
    margin-right: auto;
    width: 336px;
}
