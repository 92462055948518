@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.container {
    @extend .hoverable;
    cursor: pointer;

    display: grid;
    grid-template-columns: 30px 100px 1fr auto;
    
    font-size: 12px;
    color: $cell_secondary_text;

    background-color: $cell_bg;
    border-top: 1px solid $cell_top_border_color;
    border-right: 1px solid $border_color;
    border-left: 1px solid $border_color;
    border-bottom: 1px solid $border_color;
    padding: 15px;
    margin-top: -1px;
}

.container > div:nth-child(2) {
    color: $cell_text;
    font-weight: 900;
}
