@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.modal {
    width: 652px;
    padding: 24px;
}

.cardTitle {
    font-size: 18px;
    color: $modal_title;
    margin-bottom: 6px;
}

.description {
    color: $modal_subtitle;
    font-size: 14px;
    margin-bottom: 24px;
}

.description div {
    display: inline;
    font-weight: bold;
}

.buttons {
    float: right;
    display: inline-grid;
    grid-column-gap: 16px;
    grid-template-columns: 100px 150px;
}

.buttons div:first-child {
    @extend .outlineButton;
}

.buttons div:last-child {
    @extend .fullButton;
}
