@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.title input {
    border: 0;
    background-color: $header_bg;
    font-weight: 700;
    font-size: 33px;
    color: $header_title;
    -webkit-text-fill-color: $header_title;
    padding: 0;
    margin: 0;
    width: 100%;
    min-width: 100px;
    max-width: 875px;
    cursor: text;
}

.editableInput:hover {
    outline-style: none;
    box-shadow: 0px 0px 3px var(--secondary-color),0px 0px 3px var(--secondary-color),0px 0px 3px var(--secondary-color);
}

.title input:focus {
    outline-style: none;
    box-shadow: 0px 0px 3px var(--secondary-color),0px 0px 3px var(--secondary-color),0px 0px 3px var(--secondary-color);
}

.subtitle {
    font-weight: 600;
    font-size: 14px;
    color: $header_subtitle;
}

.options {
    float: right;
    font-size: 14px;
    font-weight: 500;
    color: $header_option;
    line-height: 40px;
}

.options a {
    text-decoration: none;
    color: $header_option;
    cursor: pointer;
}

.options a:hover {
    color: var(--secondary-color);
}
