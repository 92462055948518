@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.container {
    @extend .main;
    margin-bottom: 0;
}

.container > .settingsSection:first-child {
    margin-top: 0px;
}

.settingsSection {
    margin-top: 30px;
    margin-bottom: 42px;
}

.settingsSection > h1 {
    margin-bottom: 19px;
}

.description {
    font-size: 14px;
    color: $grey_4;
    margin-bottom: 24px;
    margin-top: 10px;
}

.labels {
    display: grid;
    grid-template-columns: 150px 150px;
    margin-left: 2px;
    margin-bottom: 6px;
}

.labels div {
    color: $exterior_label;
    font-weight: bold;
    font-size: 9px;
}

