@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.container {
    height: 25px;
    line-height: 25px;
    padding-left: 8px;
    
    background-color: $grey_2;
    color: $grey;
    font-size: 10px;
    font-weight: 600;
}
