@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.subheader {
    display: grid;
    grid-template-columns: 1fr 130px;
}

.subtitle {
    line-height: 37px;
    font-size: 24px;
    color: $content_title;
}

.createTeam {
    @extend .fullButton;
}

.grid {
    display: grid;
    grid-template-columns: 40px 1fr 80px;
    margin-top: 11px;
}

.gridChild {
    background-color: $cell_bg;
    color: $cell_text;
    line-height: 40px;
    margin-top: -1px;
}

.carrot {
    @extend .gridChild;
    @extend .hoverable;
    grid-column: 1;
    text-align: center;
    border-left: 1px solid $border_color;
    border-top: 1px solid $border_color;
    border-bottom: 1px solid $border_color;
    cursor: pointer;
}

.middle {
    @extend .gridChild;
    grid-column: 2;
    padding-left: 12px;
    border: 1px solid $border_color;
}

.teamName {
    @extend .middle;
    font-weight: bold; // TODO: somehow 500 isn't bolding it enough
}

.groupName {
    @extend .middle;
}

.createGroup {
    @extend .middle;
    @extend .hoverable;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    color: $cell_secondary_text;
    cursor: pointer;
}

.edit {
    @extend .gridChild;
    @extend .hoverable;
    text-align: center;
    grid-column: 3;
    color: var(--primary-color);
    border-right: 1px solid $border_color;
    border-top: 1px solid $border_color;
    border-bottom: 1px solid $border_color;
    cursor: pointer;
}
