@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.container {
    position: relative;
}

.program {
    @extend .row;
}

.unnamedProgram {
    @extend .program;
    color: $cell_bg;
}

.options {
    display: inline-flex;
    flex-direction: row;
    position: absolute;
    right: 20px;
    top: 0px;
    bottom: 0px;
    height: 100%;

    font-weight: 400;
    color: $grey_3;
}

.options span {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
}

.options span:hover {
    color: $grey;
    cursor: pointer;
}

.options svg {
    margin-top: auto;
    margin-bottom: auto;

    stroke: $grey_3;
    fill: white;
    width: 10px;
    height: 10px;
    margin-right: 2px;
}

.options span:hover svg {
    stroke: $grey;
}

// empty

.emptyContainer {
    margin: auto;
    margin-top: 75px;
    text-align: center;
}

.dashedContainer {
    border: 1px dashed $grey_3;
    text-align: center;
    display: inline-block;
    padding: 40px 80px 75px 80px;
}

.subheader {
    color: $grey_6;
    margin-bottom: 20px;
}

.description {
    color: $grey_6;
    font-size: 12px;
    margin-bottom: 30px;
}

.video {
    margin-bottom: 25px;
    min-width: 380px;
    min-height: 220px;
}

.dashedContainer a {
    text-decoration: none;
}

.createButton {
    @extend .fullButton;
    margin-left: auto;
    margin-right: auto;
    width: 336px;
}
