@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

// container area

// ugly name but it's a hack in for now
.containerContainer {
    position: relative;
}

.container {
    display: grid;
    grid-template-columns: 500px 500px 200px 42px;
}
.container > div {
    background-color: white;
}

// everything but the x
.container > div:not(:nth-child(4n)) {
    margin-top: -1px; // hack
    border-left: 1px solid $border_color;
    border-top: 1px solid $border_color;
    border-bottom: 1px solid $border_color;
    line-height: 42px;
    color: $cell_text;
}

// exercise
.container > div:nth-child(4n-3) {
    padding-left: 17px;
}

// link
.container > div:nth-child(4n-2) {
    padding-left: 5px;
}

// formula
.container > div:nth-child(4n-1) {
    background-color: white;
}
.container > div:nth-child(4n-1) input {
    width: 100%;
    height: 100%;
    border: 0;
    outline: none;
    color: $textfield_text;
}

// clear button floating
.clear {
    position: absolute;
    left: 935px;
    top: 0px;
    padding: 13px;
    background-color: white;
    cursor: pointer;
}
.clear:hover {
    opacity: 0.5;
}
.clear > svg {
    width: 10px;
    height: 10px;
}

// the x
.container > div:nth-child(4n) {
    @extend .hoverable;
    border: 1px solid $border_color;
    margin-top: -1px; // hack
    padding: 0px;
    cursor: pointer;
}
.container > div:nth-child(4n) img {
    padding: 13px;
}
