@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.container {
    display: block;
    position: relative;
    margin-left: 44px;
}

.containerSmall {
    @extend .container;
    height: 32px;
}

.containerBig {
    @extend .container;
    height: 48px;
}

.container > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 252px;
    height: 100%;
    background-color: white;
    border-left: 1px solid $grey_2;
    border-right: 1px solid $grey_2;
    border-bottom: 1px solid $grey_2;
}

// select

.select {
}

.containerSmall .select {
    opacity: 0;
}

.container label {
    position: absolute;
    top: 0px;
    left: 0px;
    padding-top: 9px;
    padding-left: 12px;
    color: $grey_4;
    font-size: 10px;
    font-weight: medium;
    pointer-events: none;
}

// cover

.cover {
    color: $grey_3;
    font-size: 12px;
    font-weight: medium;
    line-height: 32px;
    cursor: pointer;
}

.cover:hover {
    background-color: $grey_1;
}

.cover > span {
    font-size: 16px;
    margin-left: 17px;
    margin-right: 6px;
}
