@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.microcycleSessionTitle {
    margin-top: 13px;
    padding-top: 3px;
    padding-bottom: 6px;
    margin-left: 44px;
    font-size: 12px;
    font-weight: 600;
    color: $grey_6;
}

.microcycleSessionTitle span:hover {
    cursor: pointer;
}

.microcycleSessionTitle img {
    display: none;
    height: 7px;
}

.microcycleSessionTitle:hover img {
    display: inline;
}

// add block

.addBlock {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 310px 89px;
}

.addBlock > div:not(:first-child) {
    margin-top: 10px;
    cursor: pointer;
    color: $grey_3;
    font-size: 12px;
    font-weight: medium;
}

.addBlock > div:not(:first-child):hover {
    color: $grey_4;
}
