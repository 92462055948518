@import "src/shared_styles/styles";
@import "src/shared_styles/colors";

.main {
    width: 337px;
    text-align: center;
    margin-top: 89px;
    margin-left: auto;
    margin-right: auto;
}

.main > div {
    margin-top: 7px;
    margin-bottom: 37px;
    font-size: 24px;
    color: $login_text;
}

// FORM

.form {
    text-align: left;
}

.form > label {
    display: block;
    font-size: 16px;
    color: $login_text;
    margin-left: 2px;
    margin-top: 15px;
    margin-bottom: 5px;
}

.textInput {
    @include border-radius(3px);
    padding: 11px;
    border: 1px solid $border_color;
    width: 100%;
}

input::placeholder {
    color: $login_placeholder_text;
}

.submit {
    @extend .fullButton;
    display: block;
    border: 0;
    margin-top: 15px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;

}

.spinnerContainer {
    position: relative;
    float: right; // hack
    top: -46px; // hack
    width: 50px;
    height: 50px;
    -webkit-filter: invert(100%);
}

// ERROR

.error {
    width: 70%;
    text-align: center;
    margin-top: 89px;
    margin-left: auto;
    margin-right: auto;
}

.error h2 {
    margin-bottom: 80px;
}